import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import generateUniqueId from 'utilities/generateUniqueId';
import { FeaturedArticle } from './FeaturedArticle';

const FeaturedArticles = ({ articles, className, title }) =>
  articles &&
  articles.length > 0 && (
    <section
      className={classNames('featured-articles', className && `${className}`)}
    >
      <div className="featured-articles__frame">
        <h2 className="featured-articles__title">{title}</h2>
        <div className="featured-articles__row">
          {articles.map((featuredArticle) => (
            <div className="cards-row__column" key={generateUniqueId()}>
              <FeaturedArticle featuredArticle={featuredArticle} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );

FeaturedArticles.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      articleImage: PropTypes.shape({
        thumbnail: PropTypes.oneOfType([PropTypes.object]),
        thumbnailDescription: PropTypes.string.isRequired,
      }),
      description: PropTypes.string.isRequired,
      linkName: PropTypes.string.isRequired,
      linkUrl: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export { FeaturedArticles };
