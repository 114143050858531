import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import classNames from 'classnames';

import generateUniqueId from 'utilities/generateUniqueId';

const ClientLogos = ({ title, logos }) => {
  const isRowFull = logos && logos.length > 3;

  return (
    logos &&
    logos.length > 0 && (
      <div className="client-logos">
        <div className="client-logos__frame">
          {title && <h2 className="client-logos__title">{title}</h2>}
          <div
            className={classNames(
              'client-logos__wrapper',
              isRowFull && 'client-logos__wrapper--full'
            )}
          >
            {logos.map(
              (logo) =>
                logo.image.children[0].fluid && (
                  <div key={generateUniqueId()} className="client-logos__logo">
                    <figure className="client-logos__figure">
                      <Img
                        className="client-logos__image"
                        fluid={logo.image.children[0].fluid}
                        alt={logo.title}
                      />
                    </figure>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    )
  );
};

ClientLogos.propTypes = {
  title: PropTypes.string.isRequired,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object]),
      title: PropTypes.string,
    })
  ),
};

export { ClientLogos };
