import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import './scss/styles.scss';
import { Layout } from 'layout';
import { VideoGallery } from 'components/videoGallery';
import { handleScrollToForm } from 'utilities/handleScrollToForm';
import {
  HeroBanner,
  ClientLogos,
  SpeakingTracks,
  About,
  Book,
  ContactForm,
  FeaturedArticles,
} from './components';

const ThoughtLeaders = ({ data }) => {
  const { markdownRemark } = data;
  const speakerQuote = markdownRemark.frontmatter.speakerQuote;
  const formFieldRef = useRef(null);

  return (
    <Layout
      darkHeader
      metaTitle={
        markdownRemark.frontmatter.metaInformation.metaTitle ||
        markdownRemark.frontmatter.title
      }
      metaDescription={
        markdownRemark.frontmatter.metaInformation.metaDescription || null
      }
      metaKeywords={
        markdownRemark.frontmatter.metaInformation.metaKeywords || null
      }
      metaImage={
        markdownRemark.frontmatter.metaInformation.metaImage
          ? markdownRemark.frontmatter.metaInformation.metaImage.children[0]
              .fixed.src
          : null
      }
    >
      <HeroBanner
        speakerName={markdownRemark.frontmatter.name}
        title={markdownRemark.frontmatter.title}
        headline={markdownRemark.frontmatter.headline}
        ctaButtonText={markdownRemark.frontmatter.ctaButtonText}
        authorImage={
          markdownRemark.frontmatter.authorImage &&
          markdownRemark.frontmatter.authorImage.children[0].fluid
        }
        heroBannerBg={markdownRemark.frontmatter.heroBannerBg}
        handleScrollToForm={() =>
          handleScrollToForm(formFieldRef, '#contact-form')
        }
      />
      <ClientLogos
        title={markdownRemark.frontmatter.clientLogos.title}
        logos={markdownRemark.frontmatter.clientLogos.logos}
      />
      <SpeakingTracks
        handleScrollToForm={() =>
          handleScrollToForm(formFieldRef, '#contact-form')
        }
        title={markdownRemark.frontmatter.speakingTracks.title}
        speakingTracks={markdownRemark.frontmatter.speakingTracks.tracks}
      />
      <VideoGallery videos={markdownRemark.frontmatter.videoGallery} />
      <FeaturedArticles
        articles={markdownRemark.frontmatter.featuredArticles.articles}
        title={markdownRemark.frontmatter.featuredArticles.title}
      />
      <About
        speakerInfo={markdownRemark.frontmatter.bio}
        speakerName={markdownRemark.frontmatter.name}
        speakerTitle={markdownRemark.frontmatter.position}
      />
      <Book
        className="inverted"
        author={speakerQuote.author}
        authorsTitle={speakerQuote.authorsTitle}
        quote={speakerQuote.quote}
      />
      <ContactForm
        formFieldRef={formFieldRef}
        formTitle={markdownRemark.frontmatter.form.title}
        speakerName={markdownRemark.frontmatter.name}
      />
    </Layout>
  );
};

ThoughtLeaders.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape(),
  }),
};

export const thoughtLeadersQuery = graphql`
  query THOUGHT_LEADERS_PAGES($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        metaInformation {
          metaTitle
          metaDescription
          metaKeywords
          metaImage {
            children {
              ... on ImageSharp {
                fixed(width: 1080, height: 1080) {
                  src
                }
              }
            }
          }
        }
        title
        name
        position
        headline
        ctaButtonText
        heroBannerBg
        authorImage {
          publicURL
          children {
            ... on ImageSharp {
              fluid(maxWidth: 1219, maxHeight: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        clientLogos {
          title
          logos {
            title
            image {
              children {
                ... on ImageSharp {
                  fluid(
                    maxWidth: 400
                    maxHeight: 400
                    fit: CONTAIN
                    background: "#ffffff"
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        speakingTracks {
          title
          tracks {
            title
            description
            iconKeyword
          }
        }
        videoGallery {
          title
          thumbnail {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 470, maxHeight: 270) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          videoID
        }
        featuredArticles {
          title
          articles {
            title
            description
            articleImage {
              thumbnail {
                children {
                  ... on ImageSharp {
                    fluid(maxWidth: 450, maxHeight: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              thumbnailDescription
            }
            linkName
            linkUrl
          }
        }
        bio {
          title
          description
          thumbnail {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          followLinks {
            url
            socialPlatform
          }
        }
        book {
          title
          description
          ctaButtonText
          image {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 335, maxHeight: 387) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        speakerQuote {
          author
          authorsTitle
          quote
        }
        form {
          title
        }
      }
    }
  }
`;

export default ThoughtLeaders;
