import React from 'react';
import PropTypes from 'prop-types';

import CircledArrow from 'icons/circled-arrow.svg';
import { getSpeakingTrackIcon } from '../getSpeakingTrackIcon';

const SpeakingTrack = ({ speakingTrack, handleScrollToForm }) => (
  <button type="button" className="speaking-track" onClick={handleScrollToForm}>
    <div className="speaking-track__content">
      <figure className="speaking-track__icon">
        <img
          src={getSpeakingTrackIcon(speakingTrack.iconKeyword)}
          alt={`${speakingTrack.iconKeyword} icon.`}
        />
      </figure>
      <div className="speaking-track__text">
        <h3 className="speaking-track__title">{speakingTrack.title}</h3>
        <p className="speaking-track__description">
          {speakingTrack.description}
        </p>
      </div>
    </div>
    <span className="speaking-track__button btn-link btn-link--circled-arrow">
      Get in touch
      <CircledArrow />
    </span>
  </button>
);

SpeakingTrack.propTypes = {
  handleScrollToForm: PropTypes.func.isRequired,
  speakingTrack: PropTypes.shape({
    iconKeyword: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
};

export { SpeakingTrack };
