import FireIcon from 'images/fire.svg';
import CrystalBallIcon from 'images/crystal-ball.svg';
import MapIcon from 'images/map.svg';

const getSpeakingTrackIcon = (iconKeyword) => {
  switch (iconKeyword) {
    case 'fire':
      return FireIcon;
    case 'crystal-ball':
      return CrystalBallIcon;
    default:
      return MapIcon;
  }
};

export { getSpeakingTrackIcon };
