import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import classNames from 'classnames';

import { LoadingIndicator } from 'components/loadingIndicator';
import {
  FormField,
  FormTextArea,
  SubmitButton,
  SubmitMessageWithIcon,
} from 'components/forms';
import 'components/forms/scss/form.scss';
import { isWhiteSpace } from 'utilities';

const requiredFieldText = 'Field is required.';
const minFieldText = 'Field value is too short.';
const maxFieldText = 'Field value is too long.';
const maxSymbolCount = 5000;
const whiteSpaceFieldText = 'Field must contain letters or numbers.';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min('1', minFieldText)
    .max('150', maxFieldText)
    .required(requiredFieldText)
    .test(
      'check-whitespace',
      whiteSpaceFieldText,
      (value) => !isWhiteSpace(value)
    ),
  email: Yup.string()
    .email('Field value must be a valid email.')
    .required(requiredFieldText),
  message: Yup.string()
    .min('1', minFieldText)
    .max(maxSymbolCount.toString(), maxFieldText)
    .required(requiredFieldText)
    .test(
      'check-whitespace',
      whiteSpaceFieldText,
      (value) => !isWhiteSpace(value)
    ),
});

const ContactForm = ({ formTitle, formFieldRef, speakerName }) => {
  const [currentSymbolCount, setCurrentSymbolCount] = useState(0);
  const [formIsSubmitted, setIsFormSubmitted] = useState(false);
  const [formBeingSubmitted, setFormBeingSubmitted] = useState(false);
  const [hasSubmissionError, setSubmissionError] = useState(false);

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join('&');
  };

  const handleSubmit = async (values, actions) => {
    setFormBeingSubmitted(true);
    fetch('', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact-from',
        speaker: speakerName,
        ...values,
      }),
    })
      .then(() => {
        actions.setSubmitting(false);
        actions.setStatus('submitted');
        setIsFormSubmitted(true);
        setFormBeingSubmitted(false);

        if (typeof window !== 'undefined') {
          window.dataLayer.push({ event: 'contact' });
        }
      })
      .catch(() => {
        actions.setSubmitting(false);
        setSubmissionError(true);
        setFormBeingSubmitted(false);
      });
  };

  const handleFieldFocus = () => {
    if (hasSubmissionError) {
      setSubmissionError(false);
    }
  };

  return (
    <div
      className={classNames('contact-form', {
        submitted: formIsSubmitted || formBeingSubmitted,
      })}
      id="contact-form"
    >
      <div className="contact-form__frame">
        <h2 className="contact-form__title">{formTitle}</h2>
        <Formik
          initialValues={{
            name: '',
            email: '',
            message: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form
              name="contact-from"
              method="post"
              data-netlify="true"
              className={classNames(
                'contact-form__form form',
                { submitted: formIsSubmitted || formBeingSubmitted },
                { error: hasSubmissionError }
              )}
            >
              {hasSubmissionError && (
                <p className="form__error">Error submitting form</p>
              )}
              <div className="contact-form__field-wrapper">
                <FormField
                  formFieldRef={formFieldRef}
                  onFocus={handleFieldFocus}
                  label="Name"
                  name="name"
                />
                <FormField
                  onFocus={handleFieldFocus}
                  label="Email"
                  name="email"
                  type="email"
                />
              </div>
              <FormTextArea
                onFocus={handleFieldFocus}
                label="Message"
                name="message"
                setCurrentSymbolCount={setCurrentSymbolCount}
                maxSymbolCount={maxSymbolCount}
              />
              <input type="hidden" name="speaker" value={speakerName} />
              <div className="form__counter">
                {`${currentSymbolCount} / ${maxSymbolCount}`}
              </div>
              <SubmitButton>Send</SubmitButton>
            </Form>
          )}
        </Formik>
      </div>
      {formBeingSubmitted && <LoadingIndicator />}
      {formIsSubmitted && (
        <>
          <SubmitMessageWithIcon
            description="We will contact you shortly."
            title="Thank you!"
          />
        </>
      )}
    </div>
  );
};

ContactForm.propTypes = {
  formFieldRef: PropTypes.shape(),
  formTitle: PropTypes.string.isRequired,
  speakerName: PropTypes.string.isRequired,
};

export { ContactForm };
