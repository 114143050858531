import React from 'react';
import PropTypes from 'prop-types';

import CircledArrow from 'icons/circled-arrow.svg';
import { CMSLink } from 'components/cmsLink';
import { Image } from 'components/conditionalImage';

const FeaturedArticle = ({ featuredArticle }) => (
  <CMSLink className="featured-article" linkUrl={featuredArticle.linkUrl}>
    <div className="featured-article__figure-tile">
      <figure className="featured-article__figure">
        <Image
          className="featured-article__image"
          image={
            featuredArticle.articleImage.thumbnail &&
            featuredArticle.articleImage.thumbnail.children[0].fluid
          }
          imageText={featuredArticle.articleImage.thumbnailDescription}
        />
      </figure>
    </div>
    <div className="featured-article__text-wrapper">
      <div className="featured-article__text-content">
        <h3 className="featured-article__title">{featuredArticle.title}</h3>
        <p className="featured-article__description">
          {featuredArticle.description}
        </p>
      </div>
      <span className="featured-article__btn-link btn-link btn-link--circled-arrow">
        {featuredArticle.linkName}
        <CircledArrow />
      </span>
    </div>
  </CMSLink>
);

FeaturedArticle.propTypes = {
  featuredArticle: PropTypes.shape({
    articleImage: PropTypes.shape({
      thumbnail: PropTypes.oneOfType([PropTypes.object]),
      thumbnailDescription: PropTypes.string.isRequired,
    }),
    description: PropTypes.string.isRequired,
    linkName: PropTypes.string.isRequired,
    linkUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
};

export { FeaturedArticle };
