import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import { HeroBannerLayout } from 'components/heroBanner';

const HeroBanner = ({
  speakerName,
  title,
  headline,
  ctaButtonText,
  authorImage,
  heroBannerBg,
  handleScrollToForm,
}) => (
  <HeroBannerLayout className="speaker-aurimas-hero" bgColor={heroBannerBg}>
    {authorImage && (
      <Img
        className="speaker-aurimas-hero__image"
        fluid={authorImage}
        alt={`Speaker - ${speakerName}`}
      />
    )}
    <div className="speaker-aurimas-hero__content">
      <div className="speaker-aurimas-hero__speaker">{speakerName}</div>
      <h1 className="speaker-aurimas-hero__title">{title}</h1>
      {headline && headline !== '' && (
        <div
          className="speaker-aurimas-hero__headline"
          dangerouslySetInnerHTML={{ __html: headline }}
        />
      )}
      <button className="btn" onClick={handleScrollToForm} type="button">
        {ctaButtonText}
      </button>
    </div>
  </HeroBannerLayout>
);

HeroBanner.propTypes = {
  title: PropTypes.string.isRequired,
  speakerName: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  ctaButtonText: PropTypes.string.isRequired,
  authorImage: PropTypes.oneOfType([PropTypes.object]),
  heroBannerBg: PropTypes.string.isRequired,
  handleScrollToForm: PropTypes.func.isRequired,
};

export { HeroBanner };
