import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'components/conditionalImage';
import { CMSLink } from 'components/cmsLink';
import FacebookIcon from 'icons/facebook-icon.svg';
import LinkedInIcon from 'icons/linkedin-icon.svg';
import TwitterIcon from 'icons/twitter-icon.svg';
import generateUniqueId from 'utilities/generateUniqueId';
import { getDefaultSpeakerImage } from '../getDefaultSpeakerImage';

const About = ({ speakerInfo, speakerName, speakerTitle }) => {
  const name = speakerName.split(' ');
  const firstName = name[0];
  const lastName = name[1];

  const renderFollowLink = (followLink) => {
    switch (followLink.socialPlatform) {
      case 'Facebook':
        return (
          <>
            Facebook
            <FacebookIcon />
          </>
        );
      case 'LinkedIn':
        return (
          <>
            LinkedIn
            <LinkedInIcon />
          </>
        );
      default:
        return (
          <>
            Twitter
            <TwitterIcon />
          </>
        );
    }
  };

  return (
    <section className="about">
      <div className="about__frame">
        <div className="about__content">
          <div className="about__information-wrapper">
            <figure className="about__figure">
              <Image
                image={
                  speakerInfo.thumbnail &&
                  speakerInfo.thumbnail.children[0].fluid
                }
                imageText={speakerName}
                className="about__image"
                defaultImage={getDefaultSpeakerImage(speakerName)}
              />
            </figure>
            <div className="about__information">
              <h3 className="about__name-wrapper">
                <span className="about__name">{firstName}</span>
                <span className="about__name">{lastName}</span>
              </h3>
              <p className="about__position">{speakerTitle}</p>
            </div>
            {speakerInfo.followLinks && speakerInfo.followLinks.length > 0 && (
              <div className="about__follow-wrapper">
                <p className="about__follow-text">Follow {firstName} on:</p>
                <div className="about__follow-links-wrapper">
                  {speakerInfo.followLinks.map((followLink) => (
                    <CMSLink
                      className="about__follow-link"
                      linkUrl={followLink.url}
                      key={generateUniqueId()}
                    >
                      {renderFollowLink(followLink)}
                    </CMSLink>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="about__bio-wrapper">
            <h2 className="about__title">{speakerInfo.title}</h2>
            {speakerInfo.description && speakerInfo.description !== '' && (
              <div
                className="about__description"
                dangerouslySetInnerHTML={{ __html: speakerInfo.description }}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

About.propTypes = {
  speakerInfo: PropTypes.shape({
    description: PropTypes.string.isRequired,
    followLinks: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        socialPlatform: PropTypes.string.isRequired,
      })
    ),
    thumbnail: PropTypes.oneOfType([PropTypes.object]),
    title: PropTypes.string.isRequired,
  }),
  speakerName: PropTypes.string.isRequired,
  speakerTitle: PropTypes.string.isRequired,
};

export { About };
