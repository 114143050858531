import React from 'react';
import PropTypes from 'prop-types';

import DevbridgeLogo from 'images/devbridge-logo.svg';

const BookQuote = ({ quote, authorName, authorTitle }) =>
  quote && (
    <div className="book-quote">
      <p className="book-quote__text">{`“${quote}”`}</p>
      {authorName && (
        <div className="book-quote__author-info">
          <figure className="book-quote__company-logo">
            <img src={DevbridgeLogo} alt="Devbridge logo." />
          </figure>
          <p className="book-quote__author-name">{authorName}</p>
          {authorTitle && (
            <p className="book-quote__author-title">{authorTitle}</p>
          )}
        </div>
      )}
    </div>
  );

BookQuote.propTypes = {
  authorName: PropTypes.string.isRequired,
  authorTitle: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
};

export { BookQuote };
