import Aurimas from 'uploads/aurimas-adomavicius.jpg';

const getDefaultSpeakerImage = (speaker) => {
  switch (speaker) {
    case 'Aurimas Adomavicius':
      return Aurimas;
    default:
      return Aurimas;
  }
};

export { getDefaultSpeakerImage };
