import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import generateUniqueId from 'utilities/generateUniqueId';
import { SpeakingTrack } from './SpeakingTrack';

const SpeakingTracks = ({
  speakingTracks,
  title,
  className,
  handleScrollToForm,
}) =>
  speakingTracks &&
  speakingTracks.length > 0 && (
    <div className={classNames('speaking-tracks', className && `${className}`)}>
      <div className="speaking-tracks__frame">
        <h2 className="speaking-tracks__title">{title}</h2>
        <div className="speaking-tracks__row">
          {speakingTracks.map(
            (speakingTrack, index) =>
              index <= 2 && (
                <div
                  className="speaking-tracks__column"
                  key={generateUniqueId()}
                >
                  <SpeakingTrack
                    handleScrollToForm={handleScrollToForm}
                    speakingTrack={speakingTrack}
                  />
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );

SpeakingTracks.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleScrollToForm: PropTypes.func.isRequired,
  speakingTracks: PropTypes.arrayOf(PropTypes.shape()),
};

export { SpeakingTracks };
