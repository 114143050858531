import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { BookQuote } from './BookQuote';

const Book = ({ className, author, authorsTitle, quote }) =>
  quote && (
    <div className={classNames('book', className && `${className}`)}>
      <div className="book__frame">
        <BookQuote
          authorName={author}
          authorTitle={authorsTitle}
          quote={quote}
        />
      </div>
    </div>
  );

Book.propTypes = {
  className: PropTypes.string,
  authorsTitle: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
};

export { Book };
